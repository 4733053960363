import React from 'react';
import { useNavigate } from 'react-router-dom';

export function Contacts() {
  const Navigate = useNavigate();

  return (
    <div className='modal-area'>
      <div>
        <a
          className='contact'
          href='https://t.me/shopnedo'
          target='_blank'
          rel='noreferrer'
        >
          TELEGRAM
        </a>

        <a
          className='contact'
          href='https://www.instagram.com/nedostupnost.shop/'
          target='_blank'
          rel='noreferrer'
        >
          INSTAGRAM
        </a>

        <a
          className='contact'
          href='https://vk.com/nedostupnost?w=club72034951'
          target='_blank'
          rel='noreferrer'
        >
          ВК
        </a>
        <a
          className='contact'
          type='mail'
          href='mailto:contact@nedostupnost.com'
        >
          ПОЧТА
        </a>
      </div>
      <div className='contact' onClick={() => Navigate('/')}>
        SHOP
      </div>
    </div>
  );
}
