import React from 'react';

export function CardPriceConstructorModal({ item, addToCard }) {
  if (item.soldout) {
    return <div className='card-btn-soldout-modal'>SOLD OUT</div>;
  }

  if (item.category === 'SOON') {
    return (
      <div>
        <div className='card-price-modal'>{item.price} RUB</div>
        <div className='card-btn-buy-modal' onClick={() => addToCard(item)}>
          PRE-ORDER NOW
        </div>
      </div>
    );
  }

  if (item.price_old) {
    const discount = Math.ceil((item.price / item.price_old) * 100 - 100);

    return (
      <div>
        <div>
          <div className='card-price-old-modal'>{item.price_old}</div>
          <div className='card-new-price-info'>
            <div className='card-price-new-modal'>{item.price} RUB</div>
            <div className='card-discount-modal'>{discount}%</div>
          </div>
        </div>

        <div className='card-btn-buy-modal' onClick={() => addToCard(item)}>
          BUY NOW
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className='card-price-modal'>{item.price} RUB</div>
      <div className='card-btn-buy-modal' onClick={() => addToCard(item)}>
        BUY NOW
      </div>
    </div>
  );
}
