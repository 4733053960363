import footerLogo from './QM_LOGO.png';
import { useNavigate } from 'react-router-dom';

export function Footer() {
  const Navigate = useNavigate();

  return (
    <div className='navigation footer'>
      <div
        className='footer-logo'
        style={{
          backgroundImage: `url(${footerLogo})`,
          backgroundPosition: 'center',
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'transparent',
        }}
      ></div>
      <div className='nav_item' onClick={() => Navigate('/contacts')}>
        Связаться с нами
      </div>
      <a className='nav_item' href='https://shop.nedostupnost.com/policy.html'>
        Политика конфиденциальности
      </a>
      <a className='nav_item' href='https://shop.nedostupnost.com/offer.html'>
        Публичная оферта
      </a>
    </div>
  );
}
