import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardPriceConstructor } from './CardPriceConstructor';

export function Card({ item }) {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  const openModal = () => {
    navigate(`/product/${item.id}`);
  };

  return (
    <div
      className='col-6 col-md-3'
      onClick={openModal}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {item.soldout && <div className='sold-out'>SOLD OUT</div>}
      <div className={`photo-block ${item.soldout ? 'opacity-photo' : ''}`}>
        <LazyLoadImage
          src={hover ? item.backphoto : item.mainphoto}
          alt={item.name}
          effect='blur'
          threshold={100}
          className='card-photo'
        />
      </div>
      <div className='card-details'>
        <div className='card-title'>{item.name}</div>
        <CardPriceConstructor item={item} />
      </div>
    </div>
  );
}
