import Modal from 'react-modal';
import { useState, useEffect } from 'react';
import { useCartStore } from '../../store/cart';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import { Cart } from '../Cart/Cart';
import { useNavigate, useParams } from 'react-router-dom';
import { CardPriceConstructorModal } from './CardPriceConstructorModal';

import PhotoModal from './PhotoModal';

Modal.setAppElement('#root');
// Стиль модалки
const customStyles = {
  overlay: {
    zIndex: '11',
  },
  content: {
    width: '100%',
    height: '100%',
    padding: '0',
    inset: '0',
  },
};

const API_URL = process.env.REACT_APP_API_URL;

export function ModalCard() {
  const Navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [size, setSize] = useState('');
  const [photoBig, setPhotoBig] = useState(false);
  const [urlPhoto, setUrlPhoto] = useState(null);

  const { add } = useCartStore();

  useEffect(() => {
    const getItem = async () => {
      try {
        const response = await fetch(`${API_URL}/api/card/${id}`);
        if (response.ok) {
          const data = await response.json();
          setItem(data);
          setIsLoading(false);
          setIsOpen(true);

          return;
        }
        Navigate('/');
      } catch (e) {
        console.log(e);
        alert(e);
      }
    };
    getItem();
  }, [id]);

  const closeModal = () => {
    setIsOpen(false);
    Navigate('/');
  };

  const enlargePhoto = () => {
    setPhotoBig(!photoBig);
  };

  const addToCard = (item) => {
    if (item.sizes.length && !size) {
      return alert('Нужно выбрать размер перед покупкой!');
    }

    add({ ...item, size });
    setSize('');
  };

  if (!isLoading) {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='modal'
      >
        <div className='close-modal' onClick={closeModal}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='32'
            fill='currentColor'
            className='bi bi-x-lg'
            viewBox='0 0 16 16'
          >
            <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' />
          </svg>
        </div>

        <div className='modal-card'>
          {item.carouselphotos ? (
            <div className='modal-card-slider'>
              <Splide>
                {item.carouselphotos.map((ph, key) => {
                  return (
                    <SplideSlide rewind={true} key={key}>
                      <div className='slider-img' key={key}>
                        <img
                          src={ph}
                          alt=''
                          onClick={() => {
                            enlargePhoto();
                            setUrlPhoto(ph);
                          }}
                        />
                      </div>
                    </SplideSlide>
                  );
                })}
              </Splide>
            </div>
          ) : (
            <div>No photo</div>
          )}
          <PhotoModal
            state={photoBig}
            getState={enlargePhoto}
            photo={urlPhoto}
          />
          <div className='modal-card-info'>
            <div className='card-title-modal'>{item.name}</div>
            <div className='card-description-modal'>{item.description}</div>
            <div className='size-grid'>
              {item.sizes.length ? (
                item.sizes.split(',').map((_size, key) => {
                  return (
                    <div
                      className={_size === size ? 'pick size-btn' : 'size-btn'}
                      key={key}
                      onClick={() => setSize(_size)}
                    >
                      {_size}
                    </div>
                  );
                })
              ) : (
                <div></div>
              )}
            </div>
            <div className='card-before-purchase'>
              <CardPriceConstructorModal item={item} addToCard={addToCard} />
              <Cart />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
